import { Produto } from '../../protocols';
import { calculaValorTabelaLiquida } from '../Calculos';

type TotaisTabela = {
  valTotTabLiq: number;
  valTotTabLiqGer: number;
  valTotTabDesc: number;
  valTotRateioTabelaLiq: number;
  valTotTabFiscal: number;
  valTotTabFiscalGer: number;
};

export const calculaTotaisTabela = (produto: Produto): TotaisTabela => {
  const valTotTabLiq = calculaValorTabelaLiquida(
    produto.subtotal,
    produto.val_tot_desp_acess,
    produto.val_tot_desconto + produto.val_tot_indeniza,
    produto.val_tot_acrescimo,
    produto.val_tot_frete,
  );

  const valTotTabLiqGer = calculaValorTabelaLiquida(
    produto.subtotal,
    produto.val_tot_desp_acess,
    produto.val_tot_desconto + produto.val_tot_indeniza + produto.val_tot_verba,
    produto.val_tot_acrescimo,
    produto.val_tot_frete,
  );

  const valTotTabDesc = valTotTabLiq;
  const valTotRateioTabelaLiq = valTotTabLiq;
  const valTotTabFiscal = valTotTabLiq;
  const valTotTabFiscalGer = valTotTabLiqGer;

  return {
    valTotTabLiq,
    valTotTabLiqGer,
    valTotTabDesc,
    valTotRateioTabelaLiq,
    valTotTabFiscal,
    valTotTabFiscalGer,
  };
};
