import { Fornecedor, Loja, Produto } from '../../protocols';
import { calculaBaseIpi } from './CalculaBaseIpi';
import { calculaCreditoIcms } from './CalculaCreditoIcms';
import { calculaCustos } from './CalculaCustos';
import { calculaFcp } from './CalculaFcp';
import { calculaOutrosIcms } from './CalculaOutrosIcms';
import { calculaPisCofins } from './CalculaPisCofins';
import { calculaStVendaVarejo } from './CalculaStVendaVarejo';
import { calculaTotaisTabela } from './CalculaTotaisTabela';
import { calculaTotalStFcp } from './CalculaTotalStFcp';
import { calculaValoresFinais } from './CalculaValoresFinais';
import { calculaValoresIndividuais } from './CalculaValoresIndividuais';
import { calculaValoresUnitarios } from './CalculaValoresUnitarios';
import { defineVariaveis } from './DefineVariaveis';
import { inicializaPisCofins } from './inicializaPisCofins';
import { processarPisCofins } from './processarPisCofins';

export async function somaCampos(
  fornecedor: Fornecedor,
  loja: Loja,
  produto: Produto,
  ImportNFe = false,
  FlgICMSXml = false,
  FlgStXml = false,
  xmlImported = false,
): Promise<void> {
  const isFornRegimeST = fornecedor.flg_regime_st;
  const isInterestadual = loja.uf !== fornecedor.des_uf;
  const isFornNaoTransIcms = fornecedor.flg_nao_transf_icms;
  const isFornPauta = fornecedor.flg_pauta;
  const isFornSimples = fornecedor.flg_optante_simples;
  let isGuia = false;

  if (
    loja.uf !== fornecedor.des_uf &&
    produto.tipo_tributacao === 3 &&
    !fornecedor.flg_regime_st
  ) {
    isGuia = true;
  }

  /**
   * Define as variáveis iniciais do produto
   */
  defineVariaveis(produto, xmlImported);

  // Aplicar validação de quantidade total = 0 e val_tabela = 0

  // if (produto.qtd_total === 0) {
  //   return; // TODO - mata o processo
  // }

  // if (valTabela === 0) {
  //   return; // TODO - mata o processo
  // }

  // INICIO - Totais Tabela
  const totaisTabela = calculaTotaisTabela(produto);

  const {
    valTotTabLiq,
    valTotTabLiqGer,
    valTotTabDesc,
    valTotTabFiscal,
    valTotTabFiscalGer,
  } = totaisTabela;
  // FIM - Totais Tabela

  // INICIO - CREDITO DE ICMS
  const creditoIcmsParams = {
    isFornPauta,
    isFornNaoTransIcms,
    lojaUf: loja.uf,
    forncedorUf: fornecedor.des_uf,
    valTotTabLiq,
    valTotTabFiscal,
    valTotTabLiqGer,
  };
  const { valPerRedBCEntrada, valCredIcmsCalc, valCredIcmsGerencCalc } =
    calculaCreditoIcms(produto, creditoIcmsParams, ImportNFe, FlgICMSXml);
  // FIM Credito de ICMS

  // Calcula Valores Individuais
  calculaValoresIndividuais(produto);
  // FIM - Calcula Valores Individuais

  // ST E VENDA VAREJO
  const {
    valCustoTotSemST,
    valCustoTotSemIPISTFrete,
    valTotBCSTEntrada,
    valTotBcSt,
    valTotVendaVarejoGuia,
    valTotSTGuia,
  } = await calculaStVendaVarejo(produto, fornecedor, FlgStXml);
  // FIM - ST e Venda Varejo

  const valTotVendaVarejo = produto.val_tot_venda_varejo;

  const valTotST = produto.val_st_calc;
  const valTotSTFCP = produto.val_tot_st_fcp;
  const valTotalSTCred = 0;

  // INICIO FCP
  calculaFcp(produto, FlgICMSXml);
  // FIM - FCP

  // INICIO - PIS/COFINS

  // Inicializa PIS/COFINS
  produto.cst_pis = '';
  produto.cst_cofins = '';
  inicializaPisCofins(produto, fornecedor, loja);

  const calculaPisCofinsParams = {
    valTotTabFiscal,
    valTotTabFiscalGer,
  };
  // falta ver

  const { valCredPISCofinsGerenc } = calculaPisCofins(
    produto,
    fornecedor,
    calculaPisCofinsParams,
  );

  // const fields = {
  //   flgIgnoraPisCofins: produto.flg_ignora_pis_cofins,
  //   flgNaoPisCofins: produto.flg_nao_pis_cofins,
  //   tipoNaoPisCofins: produto.tipo_nao_pis_cofins,
  //   valTotNf,
  //   valTotSt,
  //   valTotStFcp,
  //   valTotIpi,
  //   perAliqPis,
  //   perAliqCofins,
  //   valIcms,
  //   valPisXml,
  //   valCofinsXml,
  //   valBcPisXml,
  //   valBcCofinsXml,
  //   cstPisXml,
  //   cstCofinsXml,
  //   cstPis,
  //   cstCofins,
  //   valPis,
  //   valCofins,
  // };

  // processarPisCofins(fields, fornecedor, loja, perfil, produto);

  // FIM - PIS/COFINS

  // INICIO - VALORES FINAIS
  // valTotST valTotSTFCP
  const calculaValoresFinaisParams = {
    valTotTabLiq,
    valTotTabDesc,
    valTotTabLiqGer,
    valTotST,
    valTotSTFCP,
    valTotTabFiscal,
    isGuia,
    isFornRegimeST,
  };

  const { valTotTabFinalGer, valTotTabelaFiscal } = calculaValoresFinais(
    produto,
    calculaValoresFinaisParams,
  );

  // RATEIO - NÃO SERÁ APLICADO AGORA

  /** TODO backend */
  // produto.val_tabela_liq_fiscal = valTotTabFiscal;
  /** */
  // FIM - VALORES FINAIS

  // INICIO - VALORES UNITARIOS
  const calculaValoresUnitariosParams = {
    valTotTabFinalGer,
    valTotTabelaFiscal,
    isFornPauta,
    valPerRedBCEntrada,
    valTotVendaVarejo,
    valCustoTotSemIPISTFrete,
    valTotBCSTEntrada,
    valTotBcSt,
    valCustoTotSemST,
    isFornRegimeST,
    isFornNaoTransIcms,
    isFornSimples,
    isInterestadual,
    valTotalSTCred,
    valTotVendaVarejoGuia,
    valTotSTGuia,
    valCredIcmsCalc,
    valCredIcmsGerencCalc,
    valTotTabLiq,
    valCredPISCofinsGerenc,
  };

  calculaValoresUnitarios(
    produto,
    calculaValoresUnitariosParams,
    ImportNFe,
    FlgStXml,
  );

  // Verificar esse if
  // if(produto.val_tabela === 0 || qtdEmbalagem === 0) {

  // }
  // FIM - VALORES UNITARIOS

  // INICIO - TOTAL ST FCP
  const calculaTotalStFcpParams = {
    isInterestadual,
    isFornRegimeST,
    isFornNaoTransIcms,
    isFornSimples,
    valCustoTotSemIPISTFrete,
  };
  calculaTotalStFcp(
    produto,
    calculaTotalStFcpParams,
    loja.tipo_regime,
    FlgStXml,
  );
  // FIM - TOTAL ST FCP

  // INICIO - RATEIO NO FINAL

  produto.val_tot_produto = produto.val_tabela_liq; // TODO verificar

  produto.val_tot_nf =
    produto.val_tot_produto +
    produto.val_tot_ipi +
    produto.val_tot_st +
    produto.val_tot_st_fcp;

  // vTotLiqNF := NEdtTotalNF.Value -
  // (iif(pFlgConsideraFreteBC.Checked, 0.00,  NEdtFrete.Value) +  // soma, para subtrair com o NEdtTotalNF.Value
  //  iif(pFlgConsideraDespAcessBC.Checked, 0.00,  NEdtDespAcess.Value) +
  //  iif(pFlgConsideraAcrescimoBC.Checked, 0.00,  NEdtAcrescimo.Value) - // ---->  subtrai, para somar com o NEdtTotalNF.Value
  //  iif((pTipoDescNegociado.ItemIndex=1), 0.00,  NEdtDescGeral.Value));

  // produto.val_final_item = parseFloat(
  //   (produto.val_tabela_final / produto.qtd_entrada).toFixed(2),
  // );

  // INICIO - OUTROS ICMS
  calculaOutrosIcms(produto);
  // FIM - OUTROS ICMS

  // INICIO - CALCULO BASE IPI
  calculaBaseIpi(produto);
  // FIM - CALCULO BASE IPI

  // INICIO - CALCULA CUSTOS
  const calculaCustoMedioParams = {
    tipoCusto: 0,
  };
  calculaCustos(produto, calculaCustoMedioParams);
  // FIM - CALCULA CUSTOS

  /** TODO BACKEND */

  // const TIPOCUSTO = 0 + 0;
  // let valTipoCusto = 0;

  // switch (TIPOCUSTO) {
  //   case 0:
  //     valTipoCusto = produto.val_custo_rep;
  //     break;
  //   case 1:
  //     valTipoCusto = produto.val_custo_cheio;
  //     break;
  //   case 2:
  //     valTipoCusto = produto.val_custo_scred;
  //     break;
  //   case 3:
  //     valTipoCusto = produto.val_custo_sicms;
  //     break;
  //   default:
  //     valTipoCusto = produto.val_custo_rep;
  // }

  // produto.val_custo_medio = calculaCustoMedio(
  //   valTipoCusto,
  //   produto.val_custo_medio_ant,
  //   produto.qtd_est_atual,
  //   produto.qtd_total,
  // );

  // const PERPIS = 0;
  // const PERCOFINS = 0;

  // produto.val_custo_pmz = calculaPMZ(
  //   produto.val_custo_scred,
  //   produto.per_icms_saida,
  //   produto.per_desp_op,
  //   PERPIS,
  //   PERCOFINS,
  //   produto.flg_nao_pis_cofins,
  //   produto.per_fcp_icms,
  // );

  //   MTabItensMARGEM_SCUSTO.AsFloat := CalcMargVda(MTabItensVAL_CUSTO_REP.AsCurrency,
  //     MTabItensVAL_CUSTO_SICMS.AsCurrency, 0.00,
  //     MTabItensVAL_CUSTO_PMZ.AsCurrency,
  //     MTabItensVAL_VENDA.AsCurrency,
  //     MTabItensVAL_OFERTA.AsCurrency, 0.00, 0.00,
  //     MTabItensPER_ICMS_SAIDA.AsFloat, 0.00, 0,
  //     SimNaoBooStr(pFlgNaoCredPISCof), Dica,0.00);

  // MTabItensMARGEM_SVENDA.AsFloat := CalcMargVda(MTabItensVAL_CUSTO_REP.AsCurrency,
  //     MTabItensVAL_CUSTO_SICMS.AsCurrency, 0.00,
  //     MTabItensVAL_CUSTO_PMZ.AsCurrency,
  //     MTabItensVAL_VENDA.AsCurrency,
  //     MTabItensVAL_OFERTA.AsCurrency, 0.00, 0.00,
  //     MTabItensPER_ICMS_SAIDA.AsFloat, 0.00, 1,
  //     SimNaoBooStr(pFlgNaoCredPISCof), Dica,0.00);

  // MTabItensVAL_VENDA_SUGESTAO.AsCurrency := CalcSugVda(MTabItensVAL_CUSTO_REP.AsCurrency,
  //      MTabItensVAL_CUSTO_SICMS.AsCurrency,
  //      MTabItensVAL_CUSTO_SCRED.AsCurrency,
  //      MTabItensVAL_CUSTO_PMZ.AsCurrency,
  //      MTabItensVAL_MARGEM.AsFloat, MTabItensPER_DESP_OP.AsFloat,
  //      MTabItensPER_ICMS_ENT_CALC.AsFloat,
  //      MTabItensPER_ICMS_SAIDA.AsFloat,
  //      Empresa.TipoMargem, SimNaoBooStr(pFlgNaoCredPISCof), Dica,0.00);

  // MTabItensVAL_SUGESTAO_VENDA_ANT.AsCurrency := CalcSugVda(MTabItensVAL_CUSTO_REP_ANT.AsCurrency,
  //   MTabItensVAL_CUSTO_SICMS_ANT.AsCurrency, //SICMS
  //   MTabItensVAL_CUSTO_SCRED_ANT.AsCurrency,
  //   MTabItensVAL_CUSTO_PMZ_ANT.AsCurrency,
  //   MTabItensVAL_MARGEM.AsFloat, MTabItensPER_DESP_OP.AsFloat,
  //   MTabItensPER_ICMS_ENT_CALC.AsFloat,
  //   MTabItensPER_ICMS_SAIDA.AsFloat,
  //   Empresa.TipoMargem, SimNaoBooStr(pFlgNaoCredPISCof), Dica,0.00);

  // produto.val_custo_pmz_ant = calculaPMZ(
  //   produto.val_custo_sem_imposto,
  //   produto.per_icms_saida,
  //   produto.per_desp_op,
  //   PERPIS,
  //   PERCOFINS,
  //   produto.flg_nao_pis_cofins,
  //   produto.per_fcp_icms,
  // );

  //   MTabItensMARGEM_SCUSTO.AsFloat := CalcMargVda(MTabItensVAL_CUSTO_REP_ANT.AsCurrency, 0.00, 0.00, 0.00,
  //     MTabItensVAL_VENDA.AsCurrency, MTabItensVAL_OFERTA.AsCurrency,
  //     0.00, 0.00, 0.00, 0.00, 0, False, Dica,0.00);

  // MTabItensMARGEM_SVENDA.AsFloat := CalcMargVda(MTabItensVAL_CUSTO_REP_ANT.AsCurrency, 0.00, 0.00, 0.00,
  //     MTabItensVAL_VENDA.AsCurrency, MTabItensVAL_OFERTA.AsCurrency,
  //     0.00, 0.00, 0.00, 0.00, 2, False, Dica,0.00);

  // MTabItensVAL_VENDA_SUGESTAO.AsCurrency := CalcSugVda(MTabItensVAL_CUSTO_REP_ANT.AsCurrency,
  //            MTabItensVAL_CUSTO_SICMS_ANT.AsCurrency,
  //            MTabItensVAL_CUSTO_SCRED_ANT.AsCurrency,
  //            MTabItensVAL_CUSTO_PMZ_ANT.AsCurrency,
  //            MTabItensVAL_MARGEM.AsFloat,
  //            MTabItensPER_DESP_OP.AsFloat,
  //            MTabItensPER_ICMS_ENT_CALC.AsFloat,
  //            MTabItensPER_ICMS_SAIDA.AsFloat,
  //            Empresa.TipoMargem,
  //            SimNaoBooStr(MTabItensFLG_NAO_PIS_COFINS.AsString), Dica,0.00);

  // //FIM - CUSTO MEDIO

  // INICIO - SUGESTAO

  // const unCompra = desUnidade || 'UN';
  // let unVenda = '';
  // const isProdutoIPV = produto.flg_ipv;

  // if (isProdutoIPV) {
  //   unVenda = 'KG';
  // } else {
  //   unVenda = 'UN';
  // }

  // produto.estoque_emb = formataEmbalagem(
  //   produto.qtd_est_atual,
  //   qtdEmbalagem,
  //   unCompra,
  //   unVenda,
  //   isProdutoIPV,
  // );

  // if (qtdEmbalagem !== 0) {
  //   produto.qtd_sugestao_calc = produto.qtd_sugestao / qtdEmbalagem;
  //   produto.qtd_sug_final_calc = produto.qtd_sug_final / qtdEmbalagem;
  // } else {
  //   produto.qtd_sugestao_calc = produto.qtd_sugestao;
  //   produto.qtd_sug_final_calc = produto.qtd_sug_final;
  // }

  // if (!isProdutoIPV) {
  //   produto.qtd_sugestao_calc = Math.round(produto.qtd_sugestao_calc);
  //   produto.qtd_sug_final_calc = Math.round(produto.qtd_sug_final_calc);
  // }

  // const TIPOMARGEM = 0;
  // const PISLOJA = 0;
  // const COFINSLOJA = 0;

  // produto.val_sugestao_venda = await calculaSugestaoVenda(
  //   produto.val_custo_rep,
  //   produto.val_custo_sicms,
  //   produto.val_margem,
  //   produto.per_desp_op,
  //   produto.per_icms_saida,
  //   TIPOMARGEM,
  //   1,
  //   produto.per_fcp_icms,
  //   PISLOJA,
  //   COFINSLOJA,
  //   produto.flg_nao_pis_cofins,
  // );

  // if (produto.val_oferta > 0) {
  //   produto.val_venda_oferta = produto.val_oferta;
  // } else {
  //   produto.val_venda_oferta = produto.val_venda;
  // }

  // produto.per_dif_sugestao = calculaVariacao(
  //   parseFloat(produto.val_sugestao_venda.toFixed(2)),
  //   parseFloat(produto.val_venda_oferta.toFixed(2)),
  // );

  // FIM - SUGESTAO

  // INICIO ARREDONDAMENTO

  // produto.val_tabela_liq_fiscal = parseFloat(
  //   produto.val_tabela_liq_fiscal.toFixed(2),
  // );

  // produto.val_final_item = parseFloat(produto.val_final_item.toFixed(2));

  /** */

  produto.val_tabela_final = parseFloat(produto.val_tabela_final.toFixed(2));

  produto.val_custo_cheio = parseFloat(produto.val_custo_cheio.toFixed(2));
  produto.val_custo_rep = parseFloat(produto.val_custo_rep.toFixed(2));

  // produto.val_tot_st = parseFloat(produto.val_tot_st.toFixed(2));
  produto.val_tot_isento = parseFloat(produto.val_tot_isento.toFixed(2));
  produto.val_tot_bc_icms = parseFloat(produto.val_tot_bc_icms.toFixed(2));
  produto.val_tot_icms_op = parseFloat(produto.val_tot_icms_op.toFixed(2));
  produto.val_tot_produto = parseFloat(produto.val_tot_produto.toFixed(3));
  produto.val_tabela_final_emb = parseFloat(
    produto.val_tabela_final_emb.toFixed(4),
  );

  produto.val_tot_nf = parseFloat(produto.val_tot_nf.toFixed(2));

  /** BACKEND */

  /** */
  // produto.val_tot_outros_icms = parseFloat(
  //   produto.val_tot_outros_icms.toFixed(2),
  // );
  produto.val_tot_bc_st = parseFloat(produto.val_tot_bc_st.toFixed(2));

  // produto.val_tot_st_cred = parseFloat(produto.val_tot_st_cred.toFixed(2));

  produto.val_tot_bc_st_guia_e = parseFloat(
    produto.val_tot_bc_st_guia_e.toFixed(2),
  );
  produto.val_tot_bc_st_guia_s = parseFloat(
    produto.val_tot_bc_st_guia_s.toFixed(2),
  );
  // produto.val_tot_vvarejo_guia = parseFloat(
  //   produto.val_tot_vvarejo_guia.toFixed(2),
  // );
  produto.val_tot_st_guia = parseFloat(produto.val_tot_st_guia.toFixed(2));
  produto.val_tot_bc_st_frete_dest = parseFloat(
    produto.val_tot_bc_st_frete_dest.toFixed(2),
  );
  // produto.val_tot_icms_st_frete_dest = parseFloat(
  //   produto.val_tot_icms_st_frete_dest.toFixed(2),
  // );

  // FIM - ARRENDODAMENTO
}
